@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #whyus,
  #services,
  #howitworks,
  /* #testimonials, */
  /* #team, */
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
